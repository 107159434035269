.App {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App .demo-calendar-container {
  height: 80vh;
  width: 80%;
  margin: auto;
}